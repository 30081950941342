// Map the column name to the text to be shown
const generator = () => ({
  instagram: {
    cost_per_static: "Static",
    cost_per_video: "Video",
    cost_per_reel: "Reel",
    cost_per_story: "Story",
  },
  youtube: {
    cost_per_integrated_video: "Integrated Video",
    cost_per_dedicated_video: "Dedicated Video",
    cost_per_short: "Short"
  },
  tiktok: {
    cost_per_video: "Video"
  }
})

// Export a new instance of this object
export default generator()
