// To keep track of loaded data
const loadedDataCounter = {}
const loadedDataMap = {}

window.loadedDataCounter = loadedDataCounter
window.loadedDataMap = loadedDataMap

/**
 * Get the JSON data from the public data folder
 *
 * @param {String} filename
 * @returns {Array}
 */
async function loadData(filename) {
  // If the data is already loaded
  if ((loadedDataCounter[filename] || 0) > 0) {
    // Increment the loadedDataCounter
    loadedDataCounter[filename]++

    // Return the data
    return loadedDataMap[filename]
  }

  // Get the data using fetch
  const response = await fetch("/data/" + filename)

  // Parse the json from this
  const json = await response.json()

  // Update the loadedDataCounter
  loadedDataCounter[filename] = (loadedDataCounter[filename] || 0) + 1
  // Update the loadedDataMap
  loadedDataMap[filename] = json

  // Respond with it
  return json
}

/**
 * Unload the data from the memory
 *
 * @param {String} filename
 * @returns {void}
 */
function unloadData(filename) {
  // If the data is already loaded
  if ((loadedDataCounter[filename] || 0) > 0) {
    // Decrement the loadedDataCounter
    loadedDataCounter[filename]--

    // If the counter is 0
    if (loadedDataCounter[filename] === 0) {
      // Remove the data from the map
      delete loadedDataMap[filename]
    }
  }
}

export default { loadData, unloadData }
