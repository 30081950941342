// Map the currency code to their symbols
const generator = () => ({
  inr: "₹",
  usd: "$",
  myr: "RM",
  sgd: "S$",
  idr: "Rp",
  vnd: "₫",
  aed: "د.إ"
})

export default generator()
