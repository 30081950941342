<template>
  <div class="large-page">
    <div class="d-flex justify-space-between mb-4">
      <div>
        <!-- The link back to the campaign plan -->
        <v-btn
          text
          color="primary"
          @click="$router.replace({ name: 'CampaignPlanningIndex' })"
        >
          <v-icon left>
            arrow_back
          </v-icon>

          Back
        </v-btn>
      </div>

      <div>
        <!-- If some rows have been selected -->
        <v-btn
          v-if="selectedItems.length"

          depressed
          class="mr-3"
          color="primary"
          @click="triggerDelete"
        >
          <v-icon left>clear</v-icon>
          Remove From List
        </v-btn>

        <!-- Otherwise, show a download dialog -->
        <v-btn
          v-else

          depressed
          class="mr-3"
          color="primary"
          :loading="isLoading"
          :disabled="isLoading"
          @click="triggerExport"
        >
          <v-icon left>download</v-icon>
          Export XLSX
        </v-btn>

        <v-btn
          depressed
          color="primary"
          :to="{ name: 'CampaignPlanningDetailsSearch', params: { campaignPlanId: $route.params.campaignPlanId } }"
        >
          <v-icon left>add</v-icon>
          Add More
        </v-btn>
      </div>
    </div>

    <template v-if="modelData">
      <v-data-table
        :items="modelData.items"
        hide-default-footer
        disable-pagination
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template v-slot:header>
          <thead class="v-data-table-header">
            <tr>
              <th colspan="3"></th>

              <th>
                Platform
              </th>

              <th>
                Followers
              </th>

              <th>
                Engagement Rate
              </th>

              <th>
                Locations
              </th>

              <th>
                Languages
              </th>

              <!-- <th>
                Category
              </th> -->

              <th>
                Interest
              </th>

              <th></th>
            </tr>
          </thead>
        </template>

        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="item._key"
            >
              <td>
                <v-simple-checkbox
                  color="primary"
                  v-model="selectedRows[item.id]"
                ></v-simple-checkbox>
              </td>

              <td class="pl-0 pr-2">
                {{ index + 1 }}
              </td>

              <td class="text-no-wrap py-3 pl-2">
                <div>
                  <v-edit-dialog
                    :return-value.sync="item.username"
                    @save="saveItem(item)"
                  >
                    <div class="text-subtitle-1 font-weight-bold line-height-1 d-flex align-center">
                      {{ item.username }}
                    </div>

                    <template v-slot:input>
                      <v-text-field
                        v-model="item.username"
                        label="Username"
                        style="max-width: 10em;"
                        single-line
                        counter="200"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>

                  <v-edit-dialog
                    :return-value.sync="item.name"
                    @save="saveItem(item)"
                  >
                    <div class="text-subtitle-2 font-weight-medium mt-1 line-height-1">
                      {{ item.name }}
                    </div>

                    <template v-slot:input>
                      <v-text-field
                        v-model="item.name"
                        label="Name"
                        style="max-width: 10em;"
                        single-line
                        counter="200"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </div>
              </td>

              <td>
                <v-edit-dialog
                  :return-value.sync="item.platform"
                >
                  {{ capitalizeString(item.platform) }}

                  <template v-slot:input>
                    <v-select
                      v-model="item.platform"
                      :items="platformOptions"
                      @change.passive="saveItem(item, true)"
                      style="max-width: 8em;"
                      label="Select"
                    ></v-select>
                  </template>
                </v-edit-dialog>
              </td>

              <td>
                <v-edit-dialog
                  :return-value.sync="item.followers"
                  @save="saveItem(item)"
                >
                  {{ nFormatter(item.followers) }}

                  <template v-slot:input>
                    <v-text-field
                      v-model="item.followers"
                      type="number"
                      label="Followers"
                      style="max-width: 8em;"
                      single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>

              <td>
                <v-edit-dialog
                  :return-value.sync="item.engagement_rate"
                  @save="saveItem(item)"
                >
                  {{ (parseFloat(item.engagement_rate / 100)).toFixed(2) }}%

                  <template v-slot:input>
                    <v-text-field
                      :value="parseFloat(item.engagement_rate / 100)"
                      @input="(value) => item.engagement_rate = parseInt(value * 100)"
                      type="number"
                      label="Engagement Rate"
                      style="max-width: 8em;"
                      single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </td>

              <td
                :title="item.locations.join(', ')"
              >
                <v-edit-dialog
                  :return-value.sync="item.locations"
                  @save="saveItem(item)"
                >
                  {{ item.locations[0] || 'NA' }}

                  <template v-slot:input>
                    <v-combobox
                      v-model="item.locations"
                      label="Select Multiple"
                      multiple
                      chips
                    ></v-combobox>
                  </template>
                </v-edit-dialog>
              </td>

              <td
                :title="item.languages.join(', ')"
              >
                <v-edit-dialog
                  :return-value.sync="item.languages"
                  @save="saveItem(item)"
                >
                  {{ item.languages[0] || 'NA' }}

                  <template v-slot:input>
                    <v-combobox
                      v-model="item.languages"
                      label="Select Multiple"
                      multiple
                      chips
                    ></v-combobox>
                  </template>
                </v-edit-dialog>
              </td>

              <!-- <td>
                <v-edit-dialog
                  :return-value.sync="item.category"
                >
                  {{ item.category ? capitalizeString(item.category) : "NA" }}

                  <template v-slot:input>
                    <v-select
                      v-model="item.category"
                      :items="categoryOptions"
                      @change.passive="saveItem(item, true)"
                      style="max-width: 6em;"
                      label="Select"
                    ></v-select>
                  </template>
                </v-edit-dialog>
              </td> -->

              <td
                :title="item.interests.join(', ')"
              >
                <v-edit-dialog
                  @close="saveItem(item)"
                >
                  {{ item.interests[0] || 'NA' }}

                  <template v-slot:input>
                    <v-combobox
                      v-model="item.interests"
                      :items="interestOptions"
                      label="Select Multiple"
                      multiple
                      chips
                    ></v-combobox>
                  </template>
                </v-edit-dialog>
              </td>

              <td class="text-end">
                <!-- To show contacts dialog -->
                <v-btn
                  text
                  depressed
                  color="secondary"
                  @click="selectedItem = item; shouldShowContactsDialog = true;"
                  :title="`Email: ${item.email || 'NA'} Phone: ${item.phone || ''}`"
                >
                  <v-icon>
                    contact_page
                  </v-icon>
                </v-btn>

                <!-- To show interests list -->
                <v-btn
                  text
                  depressed
                  color="secondary"
                  @click="selectedItem = item; shouldShowInfoDialog = true;"
                >
                  <v-icon>
                    info
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </template>

    <!-- Fallback -->
    <template v-else-if="!isLoading">
      <div class="text-center">
        Please try reloading the page.
      </div>
    </template>

    <!-- Confirm delete dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          Confirm your action
        </v-card-title>

        <v-card-text>
          This cannot be undone.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            :disabled="isLoading"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            :disabled="isLoading"
            :loading="isLoading"
            @click="handleDelete"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Contact details dialog -->
    <v-dialog
      v-model="shouldShowContactsDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title>
          Contact Details
        </v-card-title>

        <v-card-text
          v-if="selectedItem"
        >
          <div :class="{ 'black--text': isThemeLight }">
            <div class="text-subtitle-2 font-weight-bold">
              Email Address
            </div>

            <v-edit-dialog
              :return-value.sync="selectedItem.email"
              @save="saveItem(selectedItem, true)"
            >
              <div class="text-subtitle-1 line-height-1 d-flex align-center">
                {{ selectedItem.email || 'NA' }}
              </div>

              <template v-slot:input>
                <v-text-field
                  v-model="selectedItem.email"
                  label="Email Address"
                  counter="200"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </div>

          <div class="mt-3" :class="{ 'black--text': isThemeLight }">
            <div class="text-subtitle-2 font-weight-bold">
              Phone Number
            </div>

            <v-edit-dialog
              :return-value.sync="selectedItem.phone"
              @save="saveItem(selectedItem, true)"
            >
              <div class="text-subtitle-1 line-height-1 d-flex align-center">
                {{ selectedItem.phone || 'NA' }}
              </div>

              <template v-slot:input>
                <v-text-field
                  v-model="selectedItem.phone"
                  label="Phone Number"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="shouldShowContactsDialog = false"
          >
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show a dialog for influencer details -->
    <v-dialog
      v-model="shouldShowInfoDialog"
      max-width="300"
      scrollable
    >
      <v-card>
        <v-card-title>
          Influencer Details
        </v-card-title>

        <v-card-text v-if="selectedItem">
          <v-expansion-panels
            v-model="expandedPanels"
            accordion
            multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="font-weight-bold">
                Costs
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-list dense>
                  <template
                    v-for="(keyString, index) in Object.keys(costKeyMap[selectedItem.platform])"
                  >
                    <v-list-item
                      :key="keyString"
                    >
                      <v-list-item-icon class="mr-3">
                        <v-icon color="secondary">
                          {{ costKeyIconMap[keyString] }}
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title class="d-flex justify-space-between">
                          <span>
                            {{ costKeyMap[selectedItem.platform][keyString] }}
                          </span>

                          <v-edit-dialog
                            :return-value.sync="selectedItem.costs[keyString]"
                            @save="saveItem(selectedItem, true)"
                          >
                            <span class="font-weight-bold">
                              {{ currencySymbolMap[selectedItem.currency] }}{{ nFormatter(selectedItem.costs[keyString]) }}
                            </span>

                            <template v-slot:input>
                              <v-text-field
                                v-model="selectedItem.costs[keyString]"
                                style="max-width: 5em"
                                type="number"
                                label="Cost"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider v-if="index < Object.keys(costKeyMap[selectedItem.platform]).length - 1"></v-divider>
                  </template>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="font-weight-bold">
                Locations
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-list dense>
                  <template
                    v-for="(location, index) in selectedItem.locations"
                  >
                    <v-list-item
                      :key="location + index"
                      class="px-0"
                    >
                      <v-list-item-title>
                        {{ location }}
                      </v-list-item-title>
                    </v-list-item>

                    <v-divider v-if="index < selectedItem.locations.length - 1"></v-divider>
                  </template>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="font-weight-bold">
                Languages
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-list dense>
                  <template
                    v-for="(language, index) in selectedItem.languages"
                  >
                    <v-list-item
                      :key="language + index"
                      class="px-0"
                    >
                      <v-list-item-title>
                        {{ language }}
                      </v-list-item-title>
                    </v-list-item>

                    <v-divider v-if="index < selectedItem.languages.length - 1"></v-divider>
                  </template>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="font-weight-bold">
                Interests
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-list dense>
                  <template
                    v-for="(interest, index) in selectedItem.interests"
                  >
                    <v-list-item
                      :key="interest + index"
                      class="px-0"
                    >
                      <v-list-item-title>
                        {{ interest }}
                      </v-list-item-title>
                    </v-list-item>

                    <v-divider v-if="index < selectedItem.interests.length - 1"></v-divider>
                  </template>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// @BUGFIX https://github.com/vuetifyjs/vuetify/issues/12224#issuecomment-894201049
import Ripple from 'vuetify/lib/directives/ripple'

import costKeyMap from "@/helpers/costKeyMap.js"
import costKeyIconMap from "@/helpers/costKeyIconMap.js"
import currencySymbolMap from "@/helpers/currencySymbolMap.js"
import dataHelper from "@/helpers/dataHelper"

export default {
  name: "CampaignPlanningDetails",

  // Register the directive objects
  directives: {
    Ripple,
  },

  data: () => ({
    isLoading: false,
    modelData: null,

    selectedRows: {},

    selectedItem: null,
    shouldShowInfoDialog: false,
    shouldShowDeleteDialog: false,
    shouldShowContactsDialog: false,

    costKeyMap,
    costKeyIconMap,
    currencySymbolMap,

    expandedPanels: [0], // By default always keep the first item opened

    // The input select options
    interests: [],
    platformOptions: [
      {
        text: "Instagram",
        value: "instagram",
      },
      {
        text: "YouTube",
        value: "youtube",
      },
      {
        text: "TikTok",
        value: "tiktok",
      }
    ],
    categoryOptions: [
      {
        text: "None",
        value: null,
      },
      {
        text: "Nano",
        value: "nano",
      },
      {
        text: "Micro",
        value: "micro",
      },
      {
        text: "Macro",
        value: "macro",
      },
      {
        text: "Mega",
        value: "mega",
      },
    ]
  }),

  computed: {
    selectedItems() {
      return Object.entries(this.selectedRows)
        // Filter out rows with values "true"
        .filter((item) => item[1])
        // For each of them, compare with the key
        .map((item) => this.modelData.items.find((search) => search.id === Number(item[0])))
    },

    interestOptions() {
      return this.interests.map((item) => item.name)
    }
  },

  methods: {
    /**
     * Make network request to fetch the items for this campaign plan
     *
     * @returns {void}
     */
    async fetchItems() {
      this.isLoading = true

      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        const response = await axios({
          url: "/api/campaign-plans/" + this.$route.params.campaignPlanId
        })

        response.data.items = response.data.items.map((item) => ({
          ...item,
          costs: Array.isArray(item.costs) ? null : item.costs
        }))

        // Map response.data to have a Symbol for each item
        response.data.items = response.data.items.map((item) => ({
          ...item,
          _key: Symbol(),

          // Modify null values for languages and locations
          locations: item.locations || [],
          languages: item.languages || []
        }))
        this.modelData = response.data
      } catch (error) {
        logger({ type: "CampaignPlanningDetails Fetch Error", error })

        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      } finally {
        this.isLoading = false

        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Show the dialog for delete message to confirm it
     *
     * @returns {void}
     */
    triggerDelete() {
      this.shouldShowDeleteDialog = true
    },

    /**
     * Make network request to delete each selected row
     *
     * @returns {void}
     */
    async handleDelete() {
      this.isLoading = true

      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      for (const item of this.selectedItems) {
        try {
          await axios({
            url: `/api/campaign-plans/${this.$route.params.campaignPlanId}/items/${item.id}`,
            method: "DELETE"
          })
        } catch (error) {
          logger({ type: "CampaignPlanningDetails Delete Error", error })

          this.$store.dispatch("toasts/add", { text: "An error occurred!" })
        }
      }

      // Once all requests have been made, remove the loader
      this.$store.dispatch("loaders/remove", loaderId)

      // Reset the local state
      this.isLoading = false
      this.selectedRows = {}
      this.shouldShowDeleteDialog = false

      // Sync the model items
      this.fetchItems()
    },

    /**
     * Make the network request to queue the download
     */
    async triggerExport() {
      this.isLoading = true

      try {
        await axios({
          url: `/api/campaign-plans/${this.$route.params.campaignPlanId}/export`,
          method: "POST"
        })

        this.$store.dispatch("toasts/add", { text: "Generating your report!" })
      } catch (error) {
        logger({ type: "Error Exporting Campaign Plan", error })

        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      } finally {
        this.isLoading = false
      }
    },

    /**
     * Make the network request to save the edited data
     *
     * @param {Object} item
     * @param {Boolean} modifyOriginal
     */
    async saveItem(item, modifyOriginal = false) {
      this.isLoading = true

      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        await axios({
          url: `/api/campaign-plans/${this.$route.params.campaignPlanId}/items`,
          method: "PUT",
          data: {
            items: [
              item
            ]
          }
        })

        // Useful for select like inputs
        if (modifyOriginal) {
          const index = this.modelData.items.findIndex((search) => search.id === item.id)

          // If found, (the index is not -1)
          if (index !== -1) {
            this.modelData.items.splice(index, 1, { ...item, _key: Symbol() })
          }
        }
      } catch (error) {
        logger({ type: "CampaignPlanningDetails Update Error", error })

        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      }

      // Once all requests have been made, remove the loader
      this.$store.dispatch("loaders/remove", loaderId)

      // Reset the local state
      this.isLoading = false
    },

    /**
     * Open a new tab for the whatsapp messenger
     *
     * @param {Object} item
     * @returns {void}
     */
    openWhatsapp(item) {
      window.open("https://wa.me/" + item.phone)
    },

    /**
     * Trigger intent to open email messengers
     *
     * @param {Object} item
     * @returns {void}
     */
    openEmail(item) {
      window.open("mailto:" + item.email)
    }
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
   async created() {
    // Fetch and store interests in the store
    this.interests = await dataHelper.loadData("interests.json")
  },

  /**
   * When the Options API component is ready with initial data
   *
   * @returns {void}
   */
  mounted() {
    // Call the API to fetch details for this campaign plan
    this.fetchItems()
  },

  /**
   * Before the component is destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // Unload the interests from the store
    this.interests = []
    dataHelper.unloadData("interests.json")
  }
}
</script>
